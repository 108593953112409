import React, { useState, useEffect } from "react";
import "./Internships.css";
import Navbar from "../Navbar/NavBar";
import Preloader from '../Preloader/Preloader';
import Footer from '../Footer/footer';
import Img1 from "../../../assets/internship/intern1.jpg";
import Img2 from "../../../assets/internship/intern2.webp";
import Img3 from "../../../assets/internship/intern3.jpg";
import Img4 from "../../../assets/internship/intern4.avif";
import Img5 from "../../../assets/internship/intern5.webp";
import Img6 from "../../../assets/internship/intern6.jpg";
import Img7 from "../../../assets/internship/intern7.webp";
import Img8 from "../../../assets/internship/intern8.webp";
import Img9 from "../../../assets/internship/intern9.avif";
import Img10 from "../../../assets/internship/intern10.jpg";
import Bg from "../../../assets/Bg/internship.jpg";

// Add social media links
const socialMediaLinks = {
  facebook: "https://www.facebook.com/techniknest/",
  instagram: "https://www.instagram.com/techniknest/",
  linkedin: "https://www.linkedin.com/company/techniknest/",
  x: "https://x.com/techniknest",
  whatsapp:"https://whatsapp.com/channel/0029VaEGUWDBlHpTjOtHxS2L"
};

const internshipsData = [
  { title: "Web Developer Intern", description: "Assist with frontend and backend development tasks.", imageUrl: Img2 },
  { title: "Data Analyst Intern", description: "Analyze large datasets and provide insights.", imageUrl: Img3 },
  { title: "UX/UI Design Intern", description: "Help design user-friendly interfaces and experiences.", imageUrl: Img4  },
  { title: "AI/ML Intern", description: "Support our marketing campaigns and strategies.", imageUrl: Img5 },
  { title: "Chatbot Dev Intern", description: "Assist with product strategy and development planning.", imageUrl: Img6  },
  { title: "Cyber Security Intern", description: "Provide support to customers and handle inquiries.", imageUrl: Img7  },
  { title: "Python Developer Intern", description: "Assist with recruitment and employee onboarding.", imageUrl:Img8  },
  { title: "App Developer Intern", description: "Test new products and ensure quality standards.", imageUrl: Img9},
  { title: "Graphic Designer Intern", description: "Support business development initiatives and partnerships.", imageUrl: Img10 },
  { title: "Software Developer Intern", description: "Work on developing new features for our platform.", imageUrl: Img1 }
];

const Internships = () => {
  const [showPreloader, setShowPreloader] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPreloader(false); // Hide Preloader after 2 seconds
    }, 500);

    return () => clearTimeout(timer); // Cleanup timer on component unmount
  }, []);

  const applyNowHandler = () => {
    setIsModalOpen(true);  // Show the modal when Apply Now is clicked
  };

  const closeModal = () => {
    setIsModalOpen(false);  // Close the modal
  };

  return (
    <>
      {showPreloader ? <Preloader /> : null}

      {!showPreloader && (
        <div className="internship">
          {/* Header Section */}
          <Navbar />
          <div
            className="relative bg-cover bg-center text-white p-6 sm:p-16 lg:p-24 rounded-lg"
            style={{
              backgroundImage: `url(${Bg})`,
              height: "500px",
            }}
          >
            <div className="absolute inset-0 bg-black opacity-70 rounded-lg"></div>
            <div className="relative z-30 text-center mt-12 pt-12 sm:pt-2 pb-8 px-4 sm:px-8">
  <h1 className="text-3xl sm:text-4xl font-extrabold mb-4 sm:mb-6">Internship Opportunities</h1>
  <p className="text-lg sm:text-xl mb-6 max-w-2xl mx-auto">
    Looking for valuable internship experiences? Explore the variety of exciting internships we offer at Technik Nest! Whether you're interested in Web Development, AI, Cyber Security, or Graphic Design, we have an opportunity for you. Apply now and take the first step toward a rewarding career in the tech industry. Stay tuned for more updates on our internship programs and upcoming events!
  </p>
</div>

          </div>

          {/* Internship List Section */}
          <div className="internship-list">
            {internshipsData.map((internship, index) => (
              <div key={index} className="internship-item">
                <img src={internship.imageUrl} alt={internship.title} className="internship-item-image" />
                <div className="internship-item-content">
                  <h3 className="internship-item-title">{internship.title}</h3>
                  <p className="internship-item-description">{internship.description}</p>
                  <button onClick={applyNowHandler} className="internship-apply-btn hover:bg-blue-700 transition duration-300">Apply Now</button>
                </div>
              </div>
            ))}
          </div>

          {/* Modal Section */}
          {isModalOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white rounded-lg p-8 shadow-lg max-w-lg w-full text-center">
                <h2 className="text-2xl font-bold text-gray-800 mb-4">Coming Soon!</h2>
                <p className="text-lg text-gray-600 mb-6">This internship application will be available soon. Stay tuned!</p>

                {/* Social Media Links */}
                <div className="flex justify-center space-x-6 mb-6">
                  <a href={socialMediaLinks.facebook} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800 transition duration-300">
                    <i className="fab fa-facebook-f text-3xl"></i>
                  </a>
                  <a href={socialMediaLinks.instagram} target="_blank" rel="noopener noreferrer" className="text-pink-500 hover:text-pink-700 transition duration-300">
                    <i className="fab fa-instagram text-3xl"></i>
                  </a>
                  <a href={socialMediaLinks.linkedin} target="_blank" rel="noopener noreferrer" className="text-blue-700 hover:text-blue-900 transition duration-300">
                    <i className="fab fa-linkedin-in text-3xl"></i>
                  </a>
                  <a href={socialMediaLinks.x} target="_blank" rel="noopener noreferrer" className="text-black-500 hover:text-blue-700 transition duration-300">
  <i className="fab fa-x text-3xl"></i> {/* Twitter icon */}
</a>

                  <a href={socialMediaLinks.whatsapp} target="_blank" rel="noopener noreferrer" className="text-green-500 hover:text-green-700 transition duration-300">
                    <i className="fab fa-whatsapp text-3xl"></i>
                  </a>
                </div>

                <button onClick={closeModal} className="bg-red-600 text-white px-6 py-2 rounded-lg text-lg hover:bg-red-500 transition duration-300">
                  Close
                </button>
              </div>
            </div>
          )}

          <Footer />
        </div>
      )}
    </>
  );
};

export default Internships;
